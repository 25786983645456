const KEY = 'latest-location';
const FUNNEL_KEY = 'user-info';

/**
 * localStorage에 유저가 선택한 위치 정보를 저장하는 함수
 *
 * @param {Object} location - 저장할 위치 정보 객체
 * @param {number} location.latitude - 위도
 * @param {number} location.longitude - 경도
 */
export const saveLatestLocation = ({
  latitude,
  longitude,
}: {
  latitude: number;
  longitude: number;
}) => {
  localStorage.setItem(
    KEY,
    JSON.stringify({
      latitude,
      longitude,
    }),
  );
};

export const getSavedLatestLocation = () => {
  if (typeof window === 'undefined') {
    return null;
  }
  try {
    const location = localStorage.getItem(KEY);

    if (location) {
      return JSON.parse(location) as {
        latitude: number;
        longitude: number;
      };
    }
  } catch (error) {
    console.error('로컬 스토리지에서 위치를 검색하거나 구문 분석하는 중 오류 발생:', error);
  }
  return null;
};

export const getSavedFunnelLocation = () => {
  if (typeof window === 'undefined') {
    return null;
  }

  try {
    const userFunnelInfo = localStorage.getItem(FUNNEL_KEY);

    const location = userFunnelInfo ? JSON.parse(userFunnelInfo).location : null;

    if (location) {
      return {
        latitude: location.latitude,
        longitude: location.longitude,
      } as {
        latitude: number;
        longitude: number;
      };
    }
  } catch (error) {
    console.error('로컬 스토리지에서 위치를 검색하거나 구문 분석하는 중 오류 발생:', error);
  }
  return null;
};
